@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100..900&display=swap");

/* Appliquer la police à tout le corps */
body {
  @apply text-dark;
  font-family: "Jost", sans-serif;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* Style de la réponse dans le chat */
.chat-response {
  line-height: 1.5;
  color: #151d48;
  text-align: justify;
}

/* Style des titres et paragraphes */
.chat-response h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem; /* Espace entre titre et texte */
  margin-top: 1rem; /* Espace avant le titre */
}

.chat-response > h3:first-child {
  margin-top: 0;
}

.chat-response p {
  display: block;
  margin-bottom: 0.75rem;
}

.chat-response strong {
  font-weight: 600;
}

.chat-response img {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 24px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.chat-response table {
  width: 100%;
  border-collapse: separate;
  font-size: 0.9rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-spacing: 0;
  border-radius: 24px;
  overflow: hidden;
  margin: 1rem 0; /* Espace avant et après le tableau */
}

.chat-response td {
  padding: 10px 20px;
  border: 1px solid #e5e7eb;
  text-align: left;
  background-color: #fff;
  line-height: 1.2;
}

/* Coins arrondis pour le premier et dernier th (header) */
.chat-response th:first-child {
  border-top-left-radius: 24px;
}

.chat-response th:last-child {
  border-top-right-radius: 24px;
}

/* Coins arrondis pour la première et dernière cellule de la dernière ligne */
.chat-response tr:last-child td:first-child {
  border-bottom-left-radius: 24px;
}

.chat-response tr:last-child td:last-child {
  border-bottom-right-radius: 24px;
}

/* Améliorations visuelles */
.chat-response th {
  background-color: #ddf9e1;
  font-weight: bold;
  padding: 15px;
  border: 1px solid #e5e7eb;
  text-align: center;
}

.chat-response tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.chat-response a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.chat-response a:hover {
  color: #2980b9;
  text-decoration: underline;
}

.chat-response ol {
  list-style-type: decimal;
  padding-left: 2rem;
  margin: 0.5rem 0; /* Réduit la marge verticale de la liste */
  line-height: 1.5; /* Ajuste l'espacement vertical global */
}

.chat-response ul {
  list-style-type: disc;
  padding-left: 2rem;
  margin: 0.5rem 0; /* Réduit la marge verticale de la liste */
  line-height: 1.5; /* Ajuste l'espacement vertical global */
}

/* Suppression de la marge du dernier élément */
.chat-response > *:last-child {
  margin-bottom: 0;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}
